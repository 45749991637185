export const validateEmail = (email) => {
  var re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
};

export const emailValidation = ({
  emailValue,
  setEmailErrorMessage,
  requiredError,
  wrongEmailError,
}) => {
  let isError = false;
  // email
  if (!emailValue.trim()) {
    isError = true;
    setEmailErrorMessage(requiredError);
  } else if (!validateEmail(emailValue)) {
    isError = true;
    setEmailErrorMessage(wrongEmailError);
  }

  return isError;
};

export const passValidation = ({
  passValue,
  setPassErrorMessage,
  requiredError,
}) => {
  let isError = false;

  // pass
  if (!passValue.trim()) {
    isError = true;
    setPassErrorMessage(requiredError);
  }

  return isError;
};

export const dateValidation = ({ year, day, month }) => {
  let hasError = false;
  if (day || month || year) {
    const validationDate = new Date(+year, month - 1, +day);

    if (validationDate instanceof Date && isNaN(validationDate))
      hasError = true;
    else if (
      // year > new Date().getFullYear() ||
      validationDate - new Date() > 0 ||
      year <= new Date().getFullYear() - 110
    ) {
      hasError = true;
    } else if (
      +day !== validationDate.getDate() ||
      month - 1 !== validationDate.getMonth() ||
      +year !== validationDate.getFullYear()
    ) {
      hasError = true;
    }

    if (!hasError)
      return {
        hasError,
        birthday: `${year}-${String(month).padStart(2, 0)}-${String(
          day
        ).padStart(2, 0)}`,
      };

    return { hasError };
  }

  return { hasError };
};

export const regValidation = ({
  firstNameValue,
  setFirstNameErrorMessage,
  requiredError,
  lastNameValue,
  setLastNameErrorMessage,
  date,
  setDateErrorMessage,
  dateError,
  genderValue,
}) => {
  let isError = false;

  // first name validation
  if (!firstNameValue.length) {
    isError = true;
    setFirstNameErrorMessage(requiredError);
  }
  // last name validation
  if (!lastNameValue.length) {
    isError = true;
    setLastNameErrorMessage(requiredError);
  }

  // date validation
  const { hasError, birthday } = dateValidation(date);
  if (hasError) {
    isError = true;
    setDateErrorMessage(dateError);
  }

  const gender = genderValue === "male" ? 1 : genderValue === "female" ? 2 : 0;

  return { birthday, gender, isError };
};
