import React, { memo, useEffect, useRef, useState } from "react";

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;
const ENTER = 13;

const isStyleObject = (obj) => typeof obj === "object";

const SingleOtpInput = memo((props) => {
  const {
    placeholder,
    separator,
    isLastChild,
    inputStyle,
    activeClass,
    focus,
    isDisabled,
    hasErrored,
    errorClass,
    focusStyle,
    disabledStyle,
    shouldAutoFocus,

    index,
    value,
    className,
    isInputSecure,
    isInputNum,

    ...rest
  } = props;
  const input = useRef();

  //   Focus on first render
  //   Only when shouldAutoFocus is true
  useEffect(() => {
    const { current } = input;
    if (current && focus && shouldAutoFocus) current.focus();
    if (current && focus) {
      current.focus();
      current.select();
    }
  }, [focus, shouldAutoFocus]);

  const getClasses = (...classes) =>
    classes.filter((c) => !isStyleObject(c) && c !== false).join(" ");

  const getType = () => {
    if (isInputSecure) return "password";

    if (isInputNum) return "tel";

    return "text";
  };

  return (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      <input
        aria-label={`${index === 0 ? "Please enter verification code. " : ""}${
          isInputNum ? "Digit" : "Character"
        } ${index + 1}`}
        autoComplete="off"
        style={{
          //   ...(inputStyle && inputStyle),
          focus,
          ...(focusStyle && focusStyle),
          isDisabled,
          ...(disabledStyle && disabledStyle),
          //   hasErrored,
          //   ...(errorClass && errorClass),
        }}
        placeholder={placeholder}
        className={getClasses(
          inputStyle,
          value && activeClass,
          focus && focusStyle,
          isDisabled && disabledStyle,
          hasErrored && errorClass
        )}
        type={getType()}
        maxLength="1"
        ref={input}
        disabled={isDisabled}
        value={value ? value : ""}
        {...rest}
      />
      {!isLastChild && separator}
    </div>
  );
});

const InputOtp = (props) => {
  const {
    numInputs = 4,
    onChange = () => null,
    onEnter = () => null,
    isDisabled = false,
    shouldAutoFocus = false,
    value = "",
    isInputSecure = false,
    placeholder,
    isInputNum,
    containerStyle,
    containerClass,
    inputStyle,
    activeClass,
    focusStyle,
    separator,

    disabledStyle,
    hasErrored,
    errorClass,

    className,
  } = props;

  const [activeInput, setActiveInput] = useState(0);

  const getOtpValue = () => (value ? value.toString().split("") : []);

  const getPlaceholderValue = () => {
    if (typeof placeholder === "string") {
      if (placeholder.length === numInputs) return placeholder;

      if (placeholder.length > 0) {
        console.error(
          "Length of the placeholder should be equal to the number of inputs."
        );
      }
    }
  };

  // Helper to return OTP from input
  const handleOtpChange = (otp) => {
    const otpValue = otp.join("");

    onChange(otpValue);
  };

  const isInputValueValid = (value) => {
    const isTypeValid = isInputNum
      ? !isNaN(parseInt(value, 10))
      : typeof value === "string";

    return isTypeValid && value.trim().length === 1;
  };

  // Focus on input by index
  const focusInput = (input) => {
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);

    setActiveInput(activeInput);
  };

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1);
  };

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  const changeCodeAtFocus = (value) => {
    const otp = getOtpValue();
    otp[activeInput] = value[0];

    handleOtpChange(otp);
  };

  // Handle pasted OTP
  const handleOnPaste = (e) => {
    e.preventDefault();

    if (isDisabled) return;

    const otp = getOtpValue();

    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData("text/plain")
      .slice(0, numInputs - activeInput)
      .split("");

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
        nextActiveInput++;
      }
    }

    setActiveInput({ activeInput: nextActiveInput });
    focusInput(nextActiveInput);
    handleOtpChange(otp);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;

    if (isInputValueValid(value)) changeCodeAtFocus(value);
  };

  // Handle cases of backspace, delete, left arrow, right arrow, space
  const handleOnKeyDown = (e) => {
    if (e.keyCode === BACKSPACE || e.key === "Backspace") {
      e.preventDefault();
      changeCodeAtFocus("");
      focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === "Delete") {
      e.preventDefault();
      changeCodeAtFocus("");
    } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
      e.preventDefault();
      focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
      e.preventDefault();
      focusNextInput();
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.key === "Space"
    ) {
      e.preventDefault();
    } else if (e.keyCode == ENTER || e.key === "Enter") {
      e.preventDefault();

      if (value.length === numInputs) onEnter();
    }
  };

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput();
    } else {
      // This is a workaround for dealing with keyCode "229 Unidentified" on Android.

      if (!isInputNum) {
        const { nativeEvent } = e;

        if (
          nativeEvent.data === null &&
          nativeEvent.inputType === "deleteContentBackward"
        ) {
          e.preventDefault();
          changeCodeAtFocus("");
          focusPrevInput();
        }
      }
    }
  };

  const renderInputs = () => {
    const inputs = [];
    const otp = getOtpValue();
    const placeholder = getPlaceholderValue();

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          placeholder={placeholder && placeholder[i]}
          key={i}
          index={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onFocus={(e) => {
            setActiveInput(i);
            e.target.select();
          }}
          onBlur={() => setActiveInput(-1)}
          separator={separator}
          inputStyle={inputStyle}
          activeClass={activeClass}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorClass={errorClass}
          shouldAutoFocus={shouldAutoFocus}
          isInputNum={isInputNum}
          isInputSecure={isInputSecure}
          className={className}
        />
      );
    }

    return inputs;
  };

  return (
    <>
      <div
        style={{ ...containerStyle }}
        className={containerClass ? containerClass : ""}
      >
        {renderInputs()}
      </div>
      <style jsx global>{`
        @import "./InputOtp.scss?3";
      `}</style>
    </>
  );
};

export { InputOtp };
