import React, { useState, useCallback } from "react";

import SignUpAlertBlock from "./SignUpAlertBlock";

import { SUCCESS_SVG, CLOSE_SVG } from "../../../constants/svgConstants";
import { useTranslation } from "next-i18next";

import { GET_USER } from "../../../graphql/queries/queriesUser";
import { userData } from "../../../graphql/reactivities";
import { useLazyQuery } from "@apollo/client";

const Window6 = ({ setActiveWindow = () => null, onClickPrevButton }) => {
  const [t] = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const [checkEmail] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
  });

  const onClickNextButton = useCallback(() => {
    checkEmail().then(
      ({ data }) => {
        if (data?.user?.emailConfirmed) {
          const {
            phone,
            firstName,
            email,
            phoneConfirmed,
            emailConfirmed,
            passwordConfirmed,
          } = data?.user;

          const user = {
            phone,
            firstName,
            email,
            phoneConfirmed,
            emailConfirmed,
            passwordConfirmed,
          };

          userData(user);

          return setActiveWindow();
        }
        setErrorMessage(t("email_not_confirmed"));
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result === 5) return;
        setErrorMessage(message);
      }
    );
  }, []);

  return (
    <SignUpAlertBlock
      title={
        <>
          <div className="sign-up-section__title_svg">{SUCCESS_SVG}</div>
          {t("confirm_email_in_letter")}
        </>
      }
      nextButtonTitle={t("confirmed")}
      onClickNextButton={onClickNextButton}
      isPrevButton={true}
      onClickPrevButton={onClickPrevButton}
      isErrorBlock={!!errorMessage}
      errorBlockTitle={
        <>
          {CLOSE_SVG} {errorMessage}
        </>
      }
    />
  );
};

export default Window6;
