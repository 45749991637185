import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import SignUpAlertBlock from "../SignUp/SignUpAlertBlock";

import { useTranslation } from "next-i18next";
import { PHONE_UPDATE_VERIFY, PHONE_VERIFY } from "../../../graphql/mutations";
import { useAuth } from "../../../utils/auth";

import { useLazyQuery, useMutation } from "@apollo/client";

import { InputOtp } from "../SignUp/InputOtp";
import { GET_USER } from "../../../graphql/queries/queriesUser";
import { userData } from "../../../graphql/reactivities";

function Phone3Code({
  onClickPrevButton,
  setActiveWindow,
  wasRegistration,
  // wasConfirmation = false,
  isSignUp = false,
  phone,
  phoneRequired = true,
}) {
  const [t] = useTranslation();

  const [code, setCode] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");

  const { signIn } = useAuth();

  const [phoneVerify] = useMutation(
    phoneRequired ? PHONE_VERIFY : PHONE_UPDATE_VERIFY
  );

  const [updateUser] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: ({ user }) => {
      if (user?.phoneConfirmed) {
        const {
          firstName,
          phone,
          email,
          phoneConfirmed,
          emailConfirmed,
          passwordConfirmed,
        } = user;

        const userProperties = {
          firstName,
          phone,
          email,
          phoneConfirmed,
          emailConfirmed,
          passwordConfirmed,
        };

        userData(userProperties);
      }
    },
    onError: () => null,
  });

  const onClickNextButton = useCallback(() => {
    let errorMsg = "";
    let isError = false;

    // first name validation
    if (!code.length) {
      isError = true;
      errorMsg = t("required_field");
    } else if (code.length != 4) {
      isError = true;
      errorMsg = t("code_numbers_error");
    }

    if (isError) {
      setCodeErrorMessage(errorMsg);
      return;
    }

    phoneVerify({
      // ...(!isSignUp  && { refetchQueries: [{ query: GET_USER }] }),
      variables: { ...(phoneRequired && { phone }), code },
    }).then(
      ({ data: { phoneVerify } }) => {
        if (!phoneRequired) {
          updateUser().finally(setActiveWindow);
          return;
        }
        if (!phoneVerify) {
          return setCodeErrorMessage(t("incorrect_code"));
        }

        const { accessToken, refreshToken } = phoneVerify.token;
        signIn({ accessToken, refreshToken });
        setActiveWindow();
      },
      ({ message }) => {
        setCodeErrorMessage(message);
      }
    );
  }, [phone, code, isSignUp]);

  const onChange = useCallback((value) => {
    setCodeErrorMessage("");
    setCode(value);
  }, []);

  return (
    <SignUpAlertBlock
      title={t("enter_code")}
      nextButtonTitle={t(wasRegistration ? "register_now" : "sign_in")}
      onClickNextButton={onClickNextButton}
      isPrevButton={true}
      onClickPrevButton={onClickPrevButton}
    >
      <div className="sign-up-section__input-wrapper sign-up-section__inputs-block">
        <InputOtp
          value={code}
          onChange={onChange}
          onEnter={onClickNextButton}
          numInputs={4}
          inputStyle="otp-input"
          activeClass={"otp-input__active"}
          errorClass={"otp-input__error"}
          shouldAutoFocus
          containerClass={"otp-wrapper"}
          isInputNum
          hasErrored={!!codeErrorMessage}
        />
        {codeErrorMessage && (
          <div className="sign-up-section__input-error-message">
            <p>{codeErrorMessage}</p>
          </div>
        )}
      </div>
    </SignUpAlertBlock>
  );
}

Phone3Code.propTypes = {
  onClickPrevButton: PropTypes.func,
  wasRegistration: PropTypes.bool,

  phone: PropTypes.string,
  setActiveWindow: PropTypes.func,
};

Phone3Code.defaultProps = {
  onClickPrevButton: () => {},
  setActiveWindow: () => {},
  wasRegistration: false,
  phone: "",
};

export default Phone3Code;
