import React, { useState, useCallback } from "react";

import SignUpAlertBlock from "./SignUpAlertBlock";

import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { EMAIL_MERGE } from "../../../graphql/mutations";
import { SITE_URL } from "../../../constants/APIKeys";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { getNextUrl } from "../../../utils/getNextUrl";

function Window5({
  email,
  onClickSkipButton,
  setActiveWindow,
  onClickPrevButton,
}) {
  const [t] = useTranslation();
  const router = useRouter();
  const { asPath: activeUrl } = router;

  const nextUrl = useSelector(({ authorization }) => authorization.nextUrl);

  const [errorMessage, setErrorMessage] = useState("");

  const [emailMerge] = useMutation(EMAIL_MERGE);

  const onClickNextButton = useCallback(() => {
    const url = nextUrl ? `${SITE_URL}${nextUrl}` : `${SITE_URL}${activeUrl}`;

    emailMerge({
      variables: {
        email,
        nextUrl: getNextUrl(url),
      },
    }).then(
      () => {
        setActiveWindow();
      },
      ({ graphQLErrors, message }) => {
        if (graphQLErrors?.[0]?.result === 4) setErrorMessage(message);
      }
    );
  }, [email]);

  return (
    <SignUpAlertBlock
      title={t("merging_account")}
      subtitle={
        <Trans
          i18nKey="account_with_specific_email"
          values={{
            email,
          }}
          components={[<span />]}
        />
      }
      nextButtonTitle={t("unit")}
      onClickNextButton={onClickNextButton}
      onClickSkipButton={onClickSkipButton}
      onClickPrevButton={onClickPrevButton}
      isErrorBlock={!!errorMessage}
      errorBlockTitle={errorMessage}
      isSkipButton={true}
      isPrevButton={true}
      isFullSkipButton={true}
    />
  );
}

export default Window5;
