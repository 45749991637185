import { gql } from "@apollo/client";

export const GET_PROFILE = gql`
  query Profile {
    profile {
      id
      account
      cashbackAccount
      promocode
      rulesAccepted
      leadAllowed
      visitsCount
      clubVisitsCount
      showInEvent
      smsEnabled
      emailEnabled
    }
  }
`;

export const GET_PROFILE_PARAMETERS = gql`
  query Profile($constant: Boolean = true, $profileId: Int) {
    profile(familyProfileId: $profileId) {
      # $profileId: Int,
      # (familyProfileId: $profileId)
      id
      # parameters {
      #   id
      #   title
      #   variant
      # constant
      # minimalValue
      # maximalValue
      # variants {
      #   id
      #   title
      #   color
      #   order
      # }
      # }
      parameterHistory(constant: $constant) {
        id
        title
        value
        # minimalValue
        # maximalValue
        dateCreated
        # constant
        # parameterId
        # color
      }
    }
  }
`;

export const GET_PROFILE_DYNAMIC_PARAMETERS = gql`
  query Profile($constant: Boolean = false, $profileId: Int) {
    profile(familyProfileId: $profileId) {
      id
      parameterHistory(constant: $constant) {
        id
        title
        value
        dateCreated
        # minimalValue
        # maximalValue
        # constant
        # parameterId
        # color
      }
    }
  }
`;
