export const SIGN_UP_BUTTONS = [
  { title: "E-mail", slug: "email" },
  { title: "SMS", slug: "sms", isMain: true },
  // { title: "Facebook", slug: "facebook" },
];

export const PHONE_PLACEHOLDER_BY_LOCALE = {
  en: "+380500000000",
  ru: "+380500000000",
  uk: "+380500000000",
  kz: "+700000000000",
  de: "+490000000000",
  pl: "+480000000000",
  ka: "+995000000000",
  es: "+340000000000",
  tr: "+900000000000",
};

export const PHONE_PLACEHOLDER =
  PHONE_PLACEHOLDER_BY_LOCALE[process.env.DEFAULT_LOCALE];
